<template>
  <Modal :is-active="isActive" @close="closeModal">
    <section>
      <!-- Step 1: Enter Wallet Title -->
      <form
        @submit.prevent="triggerWalletCreation"
        v-show="walletCreationStep === 1"
        class="tw-w-full tw-space-y-8 lg:tw-w-[400px]"
      >
        <div class="tw-space-y-5">
          <AppFeaturedIcon icon="folder-plus" />

          <div class="tw-space-y-2">
            <h5
              class="tw-text-left tw-text-lg tw-font-semibold tw-leading-7 tw-text-gray-900"
            >
              Create New Wallet
            </h5>
            <p
              class="tw-text-left tw-text-sm tw-font-normal tw-leading-tight tw-text-gray-500"
            >
              This will setup a new wallet and collections account.
            </p>
          </div>
        </div>

        <AppInput
          type="text"
          label="Wallet Title*"
          placeholder="Enter Wallet Title"
          v-model="newWallet.name"
          error-label="name"
          :errors="errors"
          :required="true"
        />

        <div class="tw-grid tw-grid-cols-2 tw-gap-3 tw-py-[10px]">
          <AppButton type="outline" :full="true" @click.prevent="closeModal"
            >Cancel</AppButton
          >
          <AppButton type="dark" :full="true" button-type="submit"
            >Next</AppButton
          >
        </div>
      </form>

      <!-- Step 2: Enter Password -->
      <div v-show="walletCreationStep === 2" class="tw-w-full lg:tw-w-[400px]">
        <form class="tw-space-y-8" @submit.prevent="createWallet">
          <div class="tw-space-y-5">
            <AppFeaturedIcon icon="lock-01" />

            <div class="tw-space-y-2">
              <h5
                class="tw-text-left tw-text-lg tw-font-semibold tw-leading-7 tw-text-gray-900"
              >
                Enter password
              </h5>

              <p
                class="tw-text-left tw-text-sm tw-font-normal tw-leading-tight tw-text-gray-500"
              >
                Enter your password to proceed
              </p>
            </div>
          </div>

          <AppInput
            type="password"
            label="Password*"
            placeholder="**************"
            v-model="newWallet.password"
            error-label="password"
            :errors="errors"
            :required="true"
          />

          <div class="tw-grid tw-grid-cols-2 tw-gap-3 tw-py-[10px]">
            <AppButton
              type="outline"
              :full="true"
              @click.prevent="walletCreationStep = 1"
              >Back</AppButton
            >
            <AppButton
              type="dark"
              :full="true"
              button-type="submit"
              :disabled="isLoading"
              >Create</AppButton
            >
          </div>
        </form>
      </div>
    </section>
  </Modal>
</template>

<script setup lang="ts">
const props = defineProps({
  isActive: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["close", "wallet-created"]);

const { $axios, $toast } = useNuxtApp();
const errors = ref<object[]>([]);
const walletCreationStep = ref<number>(1);
const isLoading = ref<boolean>(false);

const newWallet = reactive({
  name: "",
  password: "",
  disposable: false,
});

const resetNewWallet = () => {
  Object.assign(newWallet, {
    name: "",
    password: "",
    disposable: false,
  });
};

// Close modal and reset states
const closeModal = () => {
  emit("close");
  walletCreationStep.value = 1;
  resetNewWallet();
};

// Trigger wallet creation: Step 1 - Check Wallet Title
const triggerWalletCreation = () => {
  if (walletCreationStep.value === 1 && !newWallet.name) {
    $toast.error("Wallet title cannot be blank.");
    return;
  }
  walletCreationStep.value = 2;
};

// Create the wallet via API call
const createWallet = async () => {
  if (!newWallet.password) {
    $toast.error("Please enter password");
    return;
  }

  errors.value = [];
  isLoading.value = true;

  try {
    useNuxtApp().callHook("page:start");

    const response = await $axios.post(
      `/schools/finance/virtualaccounts`,
      {
        name: newWallet.name,
        disposable: newWallet.disposable,
      },
      {
        headers: {
          "x-authorize": newWallet.password,
        },
      },
    );

    const { status, message } = response.data;

    if (status === true || status === "successful") {
      $toast.success(message);
      closeModal();
      emit("wallet-created", newWallet); // Emit wallet creation event
    }
  } catch (error: any) {
    if (error.response) {
      $toast.error(error.response.data.message);
      errors.value = error.response.data.errors ?? [];
    } else {
      $toast.error("An unknown error occurred.");
    }
  } finally {
    useNuxtApp().callHook("page:finish");
    isLoading.value = false;
  }
};
</script>
